.projectItem {
  border-radius: 15px;
  width: 300px;
  height: 330px;
  margin: 40px;
  box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.2);
  text-align: center;
  background-color: var(--secondary-header-color);
  color: var(--secondary-bg-color)
}

.projectItem:hover {
  box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.5);
  transition: 0.3s ease-in;
  cursor: pointer;
}

.projectItem-bgImage {
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  width: 100%;
  height: 270px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.projectItem-header {
  font-size: 25px;
  color: var(--primary-bg-color);
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
}
