.projectDetail {
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: var(--primary-bg-color);
}

.projectDetail-header {
  color: var(--secondary-header-color);
  font-size: 45px;
  padding-top: 100px;
  padding-bottom: 50px;
}

.projectDetail-top {
  display: flex;
}

.projectDetail-bottom {
  display: flex;
}

.projectDetail-description {
  display: flex;
  flex-direction: column;
  padding-left: 40px;
  width: 60%;
}
.projectDetail-img {
  width: 300px;
  border-radius: 10px;
}

.projectDetail-h2 {
  font-size: 24px;
  width: 600px;
  color: var(--tertiary-header-color);
}
.projectDetail-p {
  font-size: 16px;
  width: 600px;
  color: var(--secondary-text-color);
}

.projectDetail-bottom-p {
  justify-content: center;
  color: var(--tertiary-header-color);
  font-size: 20px;
  margin: 12px
}
.projectDetail-gitlabIcon {
  Display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 40px;
  font-size: 60px;
  color: var(--primary-text-color);
  background-color: var(--primary-bg-color);
  cursor: pointer;
  padding-bottom: 100px;
}

.projectDetail-gitlabIcon :visited {
  color: var(--primary-text-color);
}

.projectDetail-gitlabIcon :hover {
  color: var(--tertiary-header-color);
}

.projectDetail-gitlabIcon :active {
  color: var(--primary-text-color);
}


@media only screen and (max-width: 600px) {
  .footer svg {
    font-size: 50px;
  }
}
