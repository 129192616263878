.skills {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 25px;
  color: var(--primary-text-color);
  padding-bottom: 100px;
}

.skills-header {
  font-size: 40px;
  color: var(--secondary-header-color);
  margin: 0;
}

.skills-list {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  list-style: none;
  min-width: 1000px;
}

@media only screen and (max-width: 600px) {
  .skills {
    text-align: center;
  }

  .skills-header {
    font-size: 30px;
  }

  .skills-list {
    padding: 0;
  }

}
