:root {
  --primary-bg-color: #0f161b;
  --secondary-bg-color: #a4e4a7;

  --primary-header-color: #D0D6B3;
  --secondary-header-color: #9ab7a9;
  --tertiary-header-color: #3B6064;

  --primary-text-color: #edefe4;;
  --secondary-text-color: #D7C9AA;
  --tertiary-text-color: #88bee5;;

  /* color: #3E497A; */
}

body {
  padding-left: 100;
  padding-right: 100;
  margin: 0;
}

.App {
  height: auto;
  display: flex;
  flex-direction: column;
  font-family: Arial, Helvetica, sans-serif;
  background-color: var(--primary-bg-color);
}
