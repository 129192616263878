.projects {
  width: 70%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-top: 150px;
  padding-bottom: 200px;
}

.projects-projectTitle {
  text-align: center;
  color: var(--secondary-header-color);
  font-size: 60px;
  font-weight: bolder;
  padding-bottom: 90px;
}

.projects-projectList {
  width: 80vw;
  height: auto;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  place-items: center;
}

@media only screen and (max-width: 1300px) {
  .projects-projectList {
    grid-template-columns: 1fr 1fr;
  }
}

@media only screen and (max-width: 800px) {
  .projects-projectList {
    grid-template-columns: 1fr;
  }
}

@media only screen and (max-width: 800px) {
  .projects-projectList {
    width: 100%;
  }
  .projects-projectItem {
    width: 300px;
    height: 300px;
  }
}
