.experience {
  padding-top: 100px;
}

.experience-header {
  color: var(--secondary-header-color);
  text-align: center;
  padding-bottom: 90px;
  font-size: 60px;
  font-weight: bolder;
}

.dateStyle {
  color: var(--primary-text-color);
}