.intro {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: left;
  width: 70%;
  padding-top: 150px;
}

.intro-hello {
font-size: 30px;
color: var(--tertiary-header-color);
}

.intro-fullname {
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-size: 60px;
  color: var(--primary-header-color);
  height: auto;
}

.intro-tagline {
  width: 60%;
  font-size: 30px;
  color: var(--primary-text-color);
  padding-bottom: 250px;
}

@media only screen and (max-width: 600px) {
  .intro-fullname {
    font-size: 40px;
  }

  .intro-tagline {
    margin-top: 10px;
    font-size: 20px;
    width: 80%;
  }
}
