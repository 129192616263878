.home {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: "Arial", sans-serif;
}

.home-about {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60%;
  margin: 10;
  padding-top: 200px;
  padding-bottom: 200px;
}

.home-photo {
  width: 360px;
}
