.navbar {
  height: 140px;
  padding-top: 10px;
}

.navbar-links {
  display: flex;
  align-items: center;
  justify-content: right;
  text-decoration: underline;
  color: var(--primary-text-color);
}

.navbar-link {
  color: var(--primary-text-color);
  text-decoration: none;
  background-color: var(--primary-bg-color);
  margin: 15px;
  font-size: 25px;
}
.navbar-links-projects {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  place-items: center;
  justify-content: center;
}
.navbar-link:visited {
  color: var(--primary-text-color);
}

.navbar-link:hover {
  color: var(--tertiary-header-color);
  cursor: pointer;
}
.navbar-link:active {
  color: var(--tertiary-header-color);
}

.navbar-link-a {
  color: var(--primary-text-color);
  text-decoration: none;
  background-color: var(--primary-bg-color);
  margin: 20px;
  font-size: 25px;
}

.navbar-link-a:visited{
  color: var(--primary-text-color);
}
.navbar-link-a:hover{
  color: var(--tertiary-header-color);
}
.navbar-link-a:active {
  color: var(--tertiary-header-color);
}

.navbar-toggleButton {
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.navbar-toggleButton svg {
  font-size: 50px;
}

.navbar-toggleButton button {
  margin-right: 20px;
  background-color: transparent;
  border: none;
  color: var(--primary-text-color);
  cursor: pointer;
}

#open {
  height: 100vh;
}

#open .navbar-links {
  flex-direction: column;
  justify-content: flex-start;
  margin-top: 100px;
}

#open a {
  text-align: center;
}

@media only screen and (max-width: 900px) {
  .navbar-link-a {
    width: 70px;
  }
}

@media only screen and (max-width: 600px) {
  .navbar-toggleButton {
    display: flex;
  }
  #close a {
    display: none;
  }
}

@media only screen and (min-width: 600px) {
  .navbar-toggleButton {
    display: none;
  }

  .hiddenLinks {
    display: none;
  }

  #open {
    height: 100px;
  }

  #open .navbar-links {
    flex-direction: row;
  }
}
