.skills-list-item {
  display: flex;
  align-items: center;
  gap: 2%;
}

.skill-item-header {
  width: 16%;
  text-align: right;
  font-size: 24px;
  font-weight: 700;
  color: var(--tertiary-header-color);
}

.skill-item {
  font-size: 20px;
}
