.footer {
  height: 140px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: column;
  /* padding-top: 24px; */
  color: white;
  border-top: 3px solid var(--secondary-text-color);
  background-color: var(--primary-bg-color);
}

.footer-socialMedia {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 60%;
}

.footer-socialMedia svg {
  font-size: 70px;
  cursor: pointer;
}

.footer-socialMedia svg:hover {
  color: var(--tertiary-header-color);
}

@media only screen and (max-width: 600px) {
  .footer svg {
    font-size: 50px;
  }
}
